import React from 'react'
import { graphql } from 'gatsby'

import styled from 'styled-components';
import Grid from '../../../components/Helpers/Grid'

import Layout from '../../../components/Layout'
import Categories from '../../../components/Categories'

            
const HotelsWraper = styled.div`
 text-align: center;
  margin: 0 auto;
  width: 70vw;  

  h1 {
    font-size: 70px;
    /*new:*/
    line-height: 70px;
    height: 70px;
  }  

  h2 {
    padding-top: 20px;
  }
  

  @media screen and (max-width: 960px) {
    width: 80vw;  
    h1 {
      font-size: 50px;
      font-weight: 900;
      line-height: 50px;     
    }
    h2 {
      font-size: 25px;      
      font-weight: 900;
      line-height: 25px;
    }    
  }

`

const WhatToSee = ({ data }) => {
  return (
    <Layout section="travel" pageTitle="Travel">
      <HotelsWraper>      
        <h1>What to see</h1>
        <Categories data={data} columns="3" />
      </HotelsWraper>
    </Layout>
  )
}

export const query = graphql`
  query {
    sitePage {
	    path
    }    
    allMdx(filter: { frontmatter: { category : { eq: "whattosee" } } }, sort: {fields: frontmatter___date, order: DESC}) {
      nodes {
        frontmatter {
          title
          date(formatString: "MMMM DD, YYYY")
          image_alt
          category
          image {
            childImageSharp {
              gatsbyImageData(width: 500)
            }
          }          
        }    
        id
        excerpt(pruneLength: 75)
        slug
      }
    }
  }
`



export default WhatToSee